/* Sudo Class */
.ant-input[disabled] {
  color: black;
  background-color: white;
}

/* Padding */
.pd-t-5px {
  padding-top: 5px;
}

.pd-t-10px {
  padding-top: 10px;
}

/* Margin */

.mg-t-20px {
  margin-top: 20px;
}

.mg-auto-left {
  margin-left: auto;
}

/*  Font-size */

.ft-15px {
  font-size: 15px;
}

.bold {
  font-weight: bold;
}

/* Flex */
.flex {
  display: flex;
}

.flexstart {
  justify-content: flex-start;
}

.text-align-right {
  text-align: right;
}
